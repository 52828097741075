<template>
  <g fill="none" fill-rule="evenodd">
    <g>
      <g>
        <g transform="translate(-371 -1742) translate(361 1732) translate(10 10)">
          <circle cx="17" cy="17" r="17" fill="#FFF" />
          <g stroke="#23303D">
            <path
              d="M18.55 6.44h-5.634c-.143 0-.251-.137-.213-.274.23-.813.25-1.695.25-2.529 0-.46.022-.982-.03-1.487-.05-.512-.176-1.006-.48-1.395C12.11.322 11.61 0 11.057 0 9.768 0 9.565 1.261 9.445 2.282c-.141 1.193-.196 2.425-.722 3.524-.37.77-.893 1.43-1.714 1.754-.63.25-1.095.443-1.76.573-.102.019-.176.108-.176.213v8.331c0 .122.097.219.219.219h2.304c.07 0 .135.036.177.091.455.61 1.18 1.006 2.001 1.006h7.22c1.056 0 1.918-.863 1.918-1.918 0-.272-.066-.53-.183-.758-.045-.091-.022-.201.058-.264.39-.313.643-1.023.643-1.56v-.219c0-.272-.066-.53-.183-.76-.045-.09-.02-.2.058-.264.392-.31.643-.789.643-1.326v-.219c0-.272-.065-.53-.18-.76-.046-.09-.024-.201.056-.264.39-.31.642-.79.642-1.325 0-1.055-.86-1.916-1.916-1.916M4.475 17.755H.595c-.328 0-.595-.244-.595-.544V7.307c0-.3.267-.543.595-.543h3.88c.33 0 .598.243.598.543v9.904c0 .3-.267.544-.598.544"
              transform="translate(7 7)"
            />
          </g>
        </g>
      </g>
    </g>
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
